import { Controller } from "stimulus";
import axios from "axios";
import autocomplete from "autocomplete.js";

export default class extends Controller {
  static targets = ["field"];

  search(query, callback) {
    axios.get("/autocomplete", { params: { query } }).then((response) => {
      callback(response.data);
    });
  }

  connect() {
    this.ac = autocomplete(this.fieldTarget, { hint: false }, [
      {
        source: this.search,
        debounce: 200,
        templates: {
          suggestion: function (suggestion) {
            return suggestion.name + " &lt;" + suggestion.email + "&gt;";
          },
        },
      },
    ]).on("autocomplete:selected", (event, suggestion, dataset, context) => {
      const content = event.srcElement.parentNode.children[1].textContent
      var new_content = content.split(/[\s,]+/).slice(0, -1)
      new_content = new_content.join(', ')
      if(new_content != "") {
        new_content += ", "
      }
      this.ac.autocomplete.setVal(new_content + suggestion.email + ', ');
    });
  }
}
