
import Highcharts from 'highcharts'
require("highcharts/modules/data")(Highcharts)
require("highcharts/highcharts-more")(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/offline-exporting")(Highcharts)
require("highcharts/modules/map")(Highcharts)
window.Highcharts = Highcharts

var Trix  = require("trix")
require("@rails/actiontext")

import inputTimeField from '../inputTimeField'
inputTimeField()

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
const application = Application.start();
const context = require.context("../stimulus/controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

import ahoy from 'ahoy.js'
ahoy.trackView()

import * as microsoftTeams from "@microsoft/teams-js";
window.microsoftTeams = microsoftTeams;

// Trix toolbar customization
addEventListener("trix-initialize", function(event) {
  var option_list = $('#notes_box').data('defaulttextoptions')

  if(Array.isArray(option_list) && option_list.length > 1) {
    var dropdownHTML = '<select name="default-text" id="default-text-select">'
    for (var i = 0; i < option_list.length; i++) {
      dropdownHTML += '<option value="' + option_list[i][0] + '">' + option_list[i][1] + '</option>';
    }
    dropdownHTML += '</select>'
    event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforebegin", dropdownHTML)
  }
})
